import React, {useEffect} from 'react'
import {useParams}  from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Auth() {
  const params = useParams();
  const navigate = useNavigate();

 
  useEffect(() => {
    if(params.token){
      params.token = params.token.split('&user_');
      // console.log('params.token: ',params.token)
      localStorage.setItem("user", JSON.stringify(params.token[0]))
      localStorage.setItem("userId", parseInt(params.token[1]));
      // localStorage.setItem("type", res.data?.type);
      setTimeout(() => {
        navigate('/admin/dashboard');
      }, 2000);
      // navigate('/admin/dashboard');
    } else {
      navigate("/login")
      // window.location.replace('https://arkansite.mishari.dev/auth/login')
    }
  })

  // console.log(params)

  return (
    <div>Routing . . .</div>
  )
}

export default Auth