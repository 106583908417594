import { useContext , createContext , useState } from 'react';

const BookingManagementContext = createContext();

export const useBookingManagementContext = () => useContext(BookingManagementContext) 


const BookingManagementContextProvider = ({ children }) => {
    const [activeTab , setActiveTab] = useState(1);

    return (
        <BookingManagementContext.Provider
            value={{
                activeTab , setActiveTab
            }}
        >
            {children}
        </BookingManagementContext.Provider>
    )
}

export default BookingManagementContextProvider;