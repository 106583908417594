import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Protected from '../../Protected.js';

const InstructorAdmin = lazy(() => import('pages/InstructorDashboard/Admin'));
const InstructorDashboard = lazy(() => import('pages/InstructorDashboard/Dashboard'));
const SchedulingInstructor = lazy(() => import('pages/InstructorDashboard/Scheduling'))
const BookingInstructor = lazy(() => import('pages/InstructorDashboard/Booking/Booking'));
const InstructorBookingDetails = lazy(() => import('pages/InstructorDashboard/Courses/StudentDetails'));
const InstructorContact = lazy(() => import('pages/InstructorDashboard/Contact/Contact'));

export function InstructorRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Protected type={'Instructor'} component={InstructorAdmin} />}
      >

        <Route path="dashboard" element={<Protected type={'Instructor'} component={InstructorDashboard} />}/>
              <Route path="scheduling" element={<Protected type={'Instructor'} component={SchedulingInstructor} />}/>
              <Route path="courses" element={<Protected type={'Instructor'} component={BookingInstructor} />}/>
              <Route path="course/:id" element={<Protected type={'Instructor'} component={InstructorBookingDetails} />}/>
            <Route path="contacts" element={<Protected type={'Instructor'} component={InstructorContact} />}/>
      </Route>
    </Routes>
  );
}
