import { useContext , createContext , useState } from 'react';

const NewCourseContext = createContext();

export const useNewCourseContext = () => useContext(NewCourseContext) 


const NewCourseContextProvider = ({ children }) => {
    const steps = ["Main Details", "Sessions", "Exam"];
    const [currentStep, setCurrentStep] = useState(1);
    const [complete, setComplete] = useState(false);

    return (
        <NewCourseContext.Provider
            value={{
                steps , 
                currentStep , setCurrentStep ,
                complete , setComplete
            }}
        >
            {children}
        </NewCourseContext.Provider>
    )
}

export default NewCourseContextProvider;