import { useContext , createContext , useState } from 'react';

const BookRetakeContext = createContext();

export const useBookRetakeContext = () => useContext(BookRetakeContext) 


const BookRetakeContextProvider = ({ children }) => {
    const steps = ["Select Date", "Payment And Confirm"];
    const [currentStep, setCurrentStep] = useState(1);
    const [complete, setComplete] = useState(false);

    return (
        <BookRetakeContext.Provider
            value={{
                steps , 
                currentStep , setCurrentStep ,
                complete , setComplete
            }}
        >
            {children}
        </BookRetakeContext.Provider>
    )
}

export default BookRetakeContextProvider;