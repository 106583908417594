// import ExamCalender from "pages/examCalender";
// import Sidebar from "components/global/sidebar";
// import AddNewCourse from "pages/addNewCourse";
// import CourseExam from "pages/addNewCourse/CourseExam";
// import CourseSessions from "pages/addNewCourse/CourseSessions";
// import CourseManagement from "pages/courseManagement";
// import Dashboard from "pages/dashboard";
// import { BrowserRouter as Router , Routes , Route } from "react-router-dom";
// import RetakeExam from "pages/retakeExam";
// import BookRetake from "pages/bookRetake";
// import BookRetakePayment from "pages/bookRetake/BookRetakePayment";
// import BookRetakeSucceed from "pages/bookRetake/BookRetakeSucceed";
// import BookingManagement from "pages/bookingManagement";

// function App() {
//     return (
//         <main className="space bg-gray-100">
//             <Router>
//                 <Sidebar />
//                 <Routes>
//                     <Route path="/" element={<AddNewCourse />} />
//                     <Route path='/course-management' element={<AddNewCourse />} />
//                     <Route path='/add-new-course' element={<AddNewCourse />} />
//                     <Route path='/add-new-course/sessions' element={<CourseSessions />} />
//                     <Route path='/add-new-course/exam' element={<CourseExam />} />
//                     <Route path='/calendar' element={<ExamCalender />} />
//                     <Route path='/retake-exam' element={<RetakeExam />} />
//                     <Route path='/retake-exam/book-retake' element={<BookRetake />} />
//                     <Route path='/retake-exam/book-retake/payment' element={<BookRetakePayment />} />
//                     <Route path='/retake-exam/book-retake/succeed' element={<BookRetakeSucceed />} />
//                     <Route path='/booking-management/book-details' element={<BookingManagement />} />
//                 </Routes>
//             </Router>
//         </main>
//     );
// }

// export default App;

import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './pages/Login';

import { AdminRoutes } from 'routes/AdminRoutes';
import { StudentRoutes } from 'routes/StudentRoutes';
import { InstructorRoutes } from 'routes/InstructorRoutes';
import { ExaminerRoutes } from 'routes/ExaminerRoutes';
import { useTranslation } from 'react-i18next';

// import CorporateRegister from './pages/Register/CorporateRegister/CorporateRegister';
// import TrainingCenterRegister from './pages/Register/TrainingCenterRegister/TrainingCenterRegister';
// import MedicalCenterRegister from './pages/Register/MedicalCenterRegister/MedicalCenterRegister';
// import Dashboard from './pages/Dashboard/Dashboard';
// import Corporate from './pages/Dashboard/Corporate';
// import Student from './pages/Dashboard/Student';
// import NewCorporate from './pages/Dashboard/NewCorporate';
// import Booking from './pages/CorporateDashboard/Booking';
// import BookingStudent from './pages/StudentDashboard/Booking/Booking';
// import BookingInstructor from './pages/InstructorDashboard/Booking/Booking';
// import InstructorDashboard from './pages/InstructorDashboard/Dashboard';
// import StudentDetails from './pages/StudentDashboard/Courses/StudentDetails';
// import InstructorBookingDetails from './pages/InstructorDashboard/Courses/StudentDetails';
// import CourseDetails from './pages/CorporateDashboard/CourseDetails';
// import CourseDetailsConfirm from './pages/CorporateDashboard/CourseDetailsConfirm';
// import Wizard from './components/wizard/Wizard';
// import Training from './pages/Dashboard/Training';
// import BookingRequests from './pages/Dashboard/Booking';
// import BookingManagement from './pages/Dashboard/BookingManagement';
// import BookingRetake from './pages/Dashboard/BookingRetake';
// import Sidebar from "./components/global/sidebar";

// import RetakeExam from "./pages/Dashboard/retakeExam";
// import BookRetake from "./pages/Dashboard/bookRetake";
// import BookRetakePayment from './pages/Dashboard/bookRetake/BookRetakePayment'; //"./pages/bookRetake/BookRetakePayment";
// import BookRetakeSucceed from "./pages/Dashboard/bookRetake/BookRetakeSucceed";
// import BookingManagementRetake from "./pages/Dashboard/BookingManagementRetake";

// import AmanaInvoice from './pages/Dashboard/amanaInvoice/AmanaInvoice';
// import AmanaBatch from './pages/Dashboard/amanaInvoice/AmanaBatch';
// // import BranchLedger from './pages/Dashboard/LedgerSetup/BranchLedger';
// // import BranchList from './pages/Dashboard/LedgerSetup/BranchList';

// import DailyClosing from './pages/Dashboard/DailyClosing';
// import AddClosing from './pages/Dashboard/DailyClosing/AddClosing';
// import UploadClosing from './pages/Dashboard/DailyClosing/UploadClosing';
// import FinishClosing from './pages/Dashboard/DailyClosing/FinishClosing';

// import RefundPayment from './pages/Dashboard/RefundPayment';

import Protected from './Protected.js';

import Auth from './pages/Login/Auth';

// import Medical from './pages/Dashboard/Medical';
// import CurrentTrainingRevenue from './pages/Dashboard/Report/CurrentTrainingRevenue';

// // Dashboard
// const StudentDashboard = lazy(() => import("./pages/StudentDashboard/Dashboard"));

// // Chat (contact)
// // const StudentChat = lazy(() => import("./pages/StudentDashboard/Chat"));

// const Admin = lazy(() => import("./pages/Dashboard/Admin"));
// const CorporateAdmin = lazy(() => import("./pages/CorporateDashboard/Admin"));
// const StudentAdmin = lazy(() => import("./pages/StudentDashboard/Admin"));
// const ExamAdmin = lazy(() => import("./pages/ExamDashboard/Admin"));
// const StudentScore = lazy(() => import("./pages/StudentDashboard/Scores/Score"));

// const InstructorAdmin = lazy(() => import("./pages/InstructorDashboard/Admin"));

// const SchedulingExaminer = lazy(() => import('./pages/ExamDashboard/Scheduling'))
// const SchedulingInstructor = lazy(() => import('./pages/InstructorDashboard/Scheduling'))
// const SchedulingStudent = lazy(() => import('./pages/StudentDashboard/Scheduling'))
// const ExamStudent = lazy(() => import('./pages/StudentDashboard/Exams'))
// const CheckOutStudent = lazy(() => import('./pages/StudentDashboard/Exams/CheckOut'))
// const ExamSession = lazy(() => import('./pages/StudentDashboard/Exams/Session'))
// const ExamReview = lazy(() => import('./pages/StudentDashboard/Exams/Review'))
// const ExamDone = lazy(() => import('./pages/StudentDashboard/Exams/Score'))

// const ConfirmAndPay = lazy(() => import('./pages/StudentDashboard/Courses/ConfirmAndPay'))

// const PaymentStudent = lazy(() => import('./pages/StudentDashboard/Payments'))
// const PaymentCheckOutStudent = lazy(() => import('./pages/StudentDashboard/Payments/CheckOut'))

// const StudentRegister = lazy(() => import("./pages/Register/StudentRegister/StudentRegister"));

const StudentRegisterWizard = lazy(() =>
  import('./components/wizard/pages/Student'),
);
const CorporateRegisterWizard = lazy(() =>
  import('./components/wizard/pages/Corporate'),
);
const MedicalRegisterWizard = lazy(() =>
  import('./components/wizard/pages/Medical'),
);
const TrainingRegisterWizard = lazy(() =>
  import('./components/wizard/pages/Training'),
);

// const StudentRegisterWizardAdminInside = lazy(() => import("./components/wizardNew/pages/Student"));
// const CorporateRegisterWizardAdminInside = lazy(() => import("./components/wizardNew/pages/Corporate"));
// const MedicalRegisterWizardAdminInside = lazy(() => import("./components/wizardNew/pages/Medical"));
// const TrainingRegisterWizardAdminInside = lazy(() => import("./components/wizardNew/pages/Training"));

// const BranchManagement = lazy(() => import("./pages/Dashboard/BranchManagement"));
// const UserManagement = lazy(() => import("./pages/Dashboard/UserManagement"));
// const CourseManagement = lazy(() => import("./pages/Dashboard/CourseManagement"));
// const AddBranch = lazy(() => import("./pages/Dashboard/AddBranch"));
// const EditBranch = lazy(() => import("./pages/Dashboard/EditBranch"));

// const AddNewCourse = lazy(() => import("./pages/Dashboard/Course/addNewCourse"));
// const EditNewCourse = lazy(() => import("./pages/Dashboard/Course/editNewCourse"));
// const CourseExam = lazy(() => import("./pages/Dashboard/Course/addNewCourse/CourseExam"));
// const CourseSessions = lazy(() => import("./pages/Dashboard/Course/addNewCourse/CourseSessions"));

// const AddUser = lazy(() => import("./pages/Dashboard/AddUser"));
// const EditUser = lazy(() => import("./pages/Dashboard/EditUser"));
// const CorporateProfile = lazy(() => import("./components/profile/Wizard"));
// const InstructorProfile = lazy(() => import("./components/profile/Wizard"));
// const BranchProfile = lazy(() => import("./components/profile/Wizard"));
// const StudentsProfile = lazy(() => import("./components/profile/Wizard"));
// const TraningProfile = lazy(() => import("./components/profile/Wizard"));
// const MedicalProfile = lazy(() => import("./components/profile/Wizard"));
// const UsersProfile = lazy(() => import("./components/profile/Wizard"));
// const AddClassToBranch = lazy(() => import("./pages/Dashboard/AddClassToBranch"));
// const Instructors = lazy(() => import("./pages/Dashboard/Instructor"));
// const AddInstructor = lazy(() => import("./pages/Dashboard/Instructor/add"));
// const EditInstructor = lazy(() => import("./pages/Dashboard/Instructor/edit"));

// const Examiners = lazy(() => import("./pages/Dashboard/Examiner"));
// const AddExaminer = lazy(() => import("./pages/Dashboard/Examiner/add"));
// const EditExaminer = lazy(() => import("./pages/Dashboard/Examiner/edit"));

// const Scheduling = lazy(() => import("./pages/Dashboard/Scheduling"));
// const FeesSetup = lazy(() => import("./pages/Dashboard/FeesSetup"));
// const EditFees = lazy(() => import("./pages/Dashboard/EditFees"));
// const AddFeesSetup = lazy(() => import("./pages/Dashboard/AddFeesSetup"));
// const HealthCheck = lazy(() => import("./pages/Dashboard/HealthCheck"));
// const AllPayment = lazy(() => import("./pages/Dashboard/AllPayment"));
// const AllPaymentStepOne = lazy(() => import("./pages/Dashboard/AllPaymentStepOne"));

// const NewBook = lazy(() => import("./pages/Dashboard/Book/NewBook"));
// const NewBookEnd = lazy(() => import("./pages/Dashboard/Book/NewBookEnd"));

// const AddExam = lazy(() => import("./pages/Dashboard/AddExam"));
// const AddTraning = lazy(() => import('./pages/Dashboard/AddTraning'))
// const EditExam = lazy(() => import("./pages/Dashboard/EditExam"));
// const EditTraning = lazy(() => import('./pages/Dashboard/EditTraning'))

// const RefundCorporate = lazy(() => import("./pages/Dashboard/RefundCorporate"))

// // ledgers
// const BranchList = lazy(() => import("./pages/Dashboard/LedgerSetup/BranchList"));
// const BranchLedger = lazy(() => import("./pages/Dashboard/LedgerSetup/BranchLedger"));

// const Score = lazy(() => import("./pages/Dashboard/Score"));

// const RequestManagement = lazy(() => import("./pages/Dashboard/RequestManagement"))

// const AddExamInExaminer = lazy(() => import("./pages/ExamDashboard/AddExam"))

// // Advance Payments
// const AdvanceList = lazy(() => import("./pages/Dashboard/AdvancePayments/AdvanceList"));
// const AdvanceNew = lazy(() => import("./pages/Dashboard/AdvancePayments/AdvanceNew"));

// const ProductView = lazy(() => import("./pages/Dashboard/ProductView"));
// const AddProduct = lazy(() => import("./pages/Dashboard/AddProduct"));

// const StudentRegisterWizardAdmin = lazy(() =>
//   import('./components/wizardAdmin/pages/Student'),
// );
// const CorporateRegisterWizardAdmin = lazy(() =>
//   import('./components/wizardAdmin/pages/Corporate'),
// );

// const StudentRegisterionPage = lazy(() =>
//   import('./pages/Dashboard/StudentRegisterionPage')
// )
// const StudentRegistrationPage = lazy(() =>
//   import('./pages/Dashboard/StudentRegistrationPage')

// )

// // Products
const ProductsList = lazy(() => import('./pages/Products/List'));
const ProductsDetails = lazy(() => import('./pages/Products/Details'));
const ProductPayment = lazy(() => import('./pages/Products/Payment'));

// const UserGroup = lazy(() => import("./pages/Dashboard/UserGroup"))

// const InstructorScheduling = lazy(() => import("./pages/Dashboard/InstructorRelated/Scheduling"))
// const InstructorCourses = lazy(() => import("./pages/Dashboard/InstructorRelated/Booking/Booking"))
// const InstructorBooking = lazy(() => import("./pages/Dashboard/InstructorRelated/Courses/StudentDetails"))

// const ExaminerScore = lazy(() => import("./pages/ExamDashboard/Scores/Score"))
// const ExaminerScheuling = lazy(() => import("./pages/Dashboard/ExaminerRelated/Scheduling"))
// const ExaminerDashboard = lazy(() => import("./pages/ExamDashboard/Dashboard"))

// const Reports = lazy(() => import("./pages/Dashboard/Report"))
// const BranchRevenueReports = lazy(() => import("./pages/Dashboard/Report/BranchRevenue"))
// const DetailedRevenueReports = lazy(() => import("./pages/Dashboard/Report/DetailedRevenue"))
// const CurentCourseRevenueReports = lazy(() => import("./pages/Dashboard/Report/CurentCourseRevenue"))
// const CurentTrainingRevenueReports = lazy(() => import("./pages/Dashboard/Report/CurrentTrainingRevenue"))
// const StatmentCoursegRevenueReports = lazy(() => import("./pages/Dashboard/Report/StatementCourse"))
// const StatmentTraningRevenueReports = lazy(() => import("./pages/Dashboard/Report/StatementTraning"))

// const WorkFlow = lazy(() => import("./pages/Dashboard/WorkFlow/BranchList"))
// const WorkFlowConfigration = lazy(() => import("./pages/Dashboard/WorkFlow/BranchConfigration"))

// const DashboardAdmin = lazy(() => import("./pages/Dashboard/DashboardAdmin"));

// const UserApproval = lazy(() => import("./pages/Dashboard/UserApproval"))

// Corporate

// const CorporateDashboard = lazy(() => import("./pages/CorporateDashboardNew/Dashboard"))
// const CorporateBooking = lazy(() => import("./pages/CorporateDashboardNew/Booking/Booking"))
// const CorporateCourse = lazy(() => import('./pages/CorporateDashboardNew/Courses/StudentDetails'))
// const CorporateScheduling = lazy(() => import("./pages/CorporateDashboardNew/Scheduling"))

function GlobalDebug(debugOn, suppressAll) {
  var savedConsole = console;
  var suppress = suppressAll || false;
  if (debugOn === false) {
    // supress the default console functionality
    console = {};
    console.log = function () {};
    // supress all type of consoles
    if (suppress) {
      console.info = function () {};
      console.warn = function () {};
      console.error = function () {};
    } else {
      console.info = savedConsole.info;
      console.warn = savedConsole.warn;
      console.error = savedConsole.error;
    }
  } else {
    console = savedConsole;
  }
}

function App() {
  // useEffect(() => {
  //   (process.env.NODE_ENV === "production" || true ||
  //    process.env.REACT_APP_ENV === "STAGING") &&
  //     GlobalDebug(false,true);
  // }, []);
  const { t, i18n } = useTranslation();
  let lang = localStorage.getItem("lang") || 'ar';
  const isRTL = i18n.language === 'en' ? 'ltr' : 'rtl';

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  return (
    <div className={isRTL ? 'rtl' : 'ltr'} lang={i18n.language}>
      <Suspense fallback={<h3>Loading...</h3>}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Login />} />
            <Route path="/Auth/:token" element={<Auth />} />

            <Route
              path="/student-register"
              element={<StudentRegisterWizard />}
            />

            <Route
              path="/training-register"
              element={<TrainingRegisterWizard />}
            />
            <Route
              path="/medical-register"
              element={<MedicalRegisterWizard />}
            />
            <Route
              path="/corporate-register"
              element={<CorporateRegisterWizard />}
            />

            <Route path="/admin/*" element={<AdminRoutes />} />

            <Route path="/student/*" element={<StudentRoutes />} />

            <Route path="/instructor/*" element={<InstructorRoutes />} />

            <Route path="/examiner/*" element={<ExaminerRoutes />} />

            {/* <Route path="/examiner" element={<ExamAdmin />} >
              <Route path="dashboard" element={<ExaminerDashboard />}/>
              <Route path="booking" element={<BookingStudent />}/>
              <Route path="add-exam/:id" element={<AddExamInExaminer />}/>
              <Route path="courses" element={<BookingStudent />}/>
              <Route path="course/:id" element={<StudentDetails />}/>
              <Route path="courses/confirm/:id" element={<CourseStudentDetailsConfirm />}/>

              <Route path="scheduling" element={<SchedulingExaminer />}/>
              <Route path="exam" element={<ExamStudent />}/>
              <Route path="scores" element={<ExaminerScore />}/>
            </Route> */}

            {/* <Route path="/admin/*" element={<Protected component={Admin} />} >


              <Route path="dashboard" element={<Protected component={DashboardAdmin} />}/>


              <Route path="user-group" element={<Protected component={UserGroup} type={'super admin'} />}/>
              <Route path="corporate" element={<Protected permission={['read_4']} component={Corporate} />}/>
              <Route path="corporate/profile" element={<Protected permission={['read_4']} component={CorporateProfile} />}/>
              <Route path="corporate/add-new" element={<Protected permission={['create_4']} component={CorporateRegisterWizard} />}/>
              <Route path="corporate/edit/:id" element={<Protected permission={['edit_4']} component={CorporateRegisterWizard} />}/>
              <Route path="students" element={<Protected permission={['read_3']} component={Student} />}/>
              <Route path="students/add-new" element={<Protected permission={['create_3']} component={StudentRegisterWizard} />}/>
              <Route path="students/edit/:id" element={<Protected permission={['edit_3']} component={StudentRegisterWizard} />}/>
              <Route path="training-center/add-new" element={<Protected permission={['create_4']} component={TrainingRegisterWizard} />}/>
              <Route path="training-center/edit/:id" element={<Protected permission={['edit_4']} component={TrainingRegisterWizard} />}/>
              <Route path="medical-center/add-new" element={<Protected permission={['create_4']} component={MedicalRegisterWizard} />}/>
              <Route path="medical-center/edit/:id" element={<Protected permission={['edit_4']} component={MedicalRegisterWizard} />}/>
              <Route path="medical-center" element={<Protected permission={['read_4']} component={Medical} />}/>
              <Route path="training-center" element={<Protected permission={['read_4']} component={Training} />}/>
              <Route path="booking-requests" element={<Protected permission={['read_9']} component={BookingRequests} />}/>
              <Route path="booking-management" element={<Protected permission={['read_9']} component={BookingManagement} />}/>
              <Route path="request-management" element={<Protected permission={['read_2']} component={RequestManagement} />}/>
              <Route path="user-approval" element={<Protected permission={['read_2']} component={UserApproval} />}/>
              <Route path="booking-retake-exam" element={<Protected permission={['create_9']} component={BookingRetake} />}/>
              <Route path="branch-management" element={<Protected permission={['read_2']} component={BranchManagement} />}/>
              <Route path="course-management" element={<Protected permission={['read_6']} component={CourseManagement} />}/>
              <Route path="add-branch" element={<Protected permission={['create_2']} component={AddBranch} />}/>
              <Route path="edit-branch/:id" element={<Protected permission={['edit_2']} component={EditBranch} />}/>

              <Route path='course-management-test' element={<Protected permission={['read_6']} component={AddNewCourse} />} />
              <Route path='add-new-course' element={<Protected permission={['create_6']} component={AddNewCourse} />} />
              <Route path='edit-course/:id' element={<Protected permission={['edit_6']} component={EditNewCourse} />} />
              <Route path='add-new-course/:id' element={<Protected permission={['create_6']} component={AddNewCourse} />} />
              <Route path='add-new-course/sessions/:id' element={<Protected permission={['create_6']} component={CourseSessions} />} />

              <Route path='add-new-course/exam/:id' element={<Protected permission={['create_6']} component={CourseExam} />} />
              <Route path="add-user" element={<Protected permission={['create_1']} component={AddUser} />}/>
              <Route path="edit-user/:id" element={<Protected permission={['edit_1']} component={EditUser} />}/>
              <Route path="add-class-to-branch/:id" element={<Protected permission={['create_2']} component={AddClassToBranch} />}/>
              <Route path="branches/profile/:id" element={<Protected permission={['read_2']} component={BranchProfile} />}/>
              <Route path="students/profile/:id" element={<Protected permission={['read_3']} component={StudentsProfile} />}/>
              <Route path="training-center/profile/:id" element={<Protected permission={['read_4']} component={TraningProfile} />}/>
              <Route path="medical-center/profile/:id" element={<Protected permission={['read_4']} component={MedicalProfile} />}/>
              <Route path="instructor/profile/:id" element={<Protected permission={['read_1']} component={InstructorProfile} />}/>
              <Route path="users/profile/:id" element={<Protected permission={['read_1']} component={UsersProfile} />}/>
              <Route path="user-management" element={<Protected permission={['read_1']} component={UserManagement} />}/>
              <Route path="instructors" element={<Protected permission={['read_1']} component={Instructors} />}/>
              <Route path="add-instructor" element={<Protected permission={['create_1']} component={AddInstructor} />}/>
              <Route path="edit-instructor/:id" element={<Protected permission={['edit_1']} component={EditInstructor} />}/>
              <Route path="examiners" element={<Protected permission={['read_1']} component={Examiners} />}/>
              <Route path="add-examiner" element={<Protected permission={['create_1']} component={AddExaminer} />}/>
              <Route path="edit-examiner/:id" element={<Protected permission={['edit_1']} component={EditExaminer} />}/>


              <Route path="Scheduling" element={<Protected permission={['read_8']} component={Scheduling} />}/>
              <Route path="fees-setup" element={<Protected permission={['read_10']} component={FeesSetup} />}/>
              <Route path="add-fees-setup" element={<Protected permission={['create_10']} component={AddFeesSetup} />}/>
              <Route path="edit-fees/:id" element={<Protected permission={['edit_10']} component={EditFees} />}/>


              <Route path="all-payments" element={<Protected permission={['read_10', 'read_17']} component={AllPayment} />}/>
              <Route path="all-payments/1" element={<Protected permission={['read_10', 'read_17']} component={AllPaymentStepOne} />}/>
              <Route path="health-check" element={<Protected permission={['read_20']} component={HealthCheck} />}/>
              <Route path="new-book" element={<Protected permission={['create_9']} component={NewBook} />}/>
              <Route path="new-book/end" element={<Protected permission={['create_9']} component={NewBookEnd} />}/>
              <Route path="add-exam" element={<Protected permission={['create_8']} component={AddExam} />}/>
              <Route path="edit-exam/:id" element={<Protected permission={['create_8']} component={EditExam} />}/>
              <Route path="add-training" element={<Protected permission={['create_8']} component={AddTraning} />} />
              <Route path="edit-training/:id" element={<Protected permission={['create_8']} component={EditTraning} />} />
              <Route path="daily-closing" element={<Protected permission={['read_10', 'read_17']} component={DailyClosing} />}/>
              <Route path="daily-closing/add-closing" element={<Protected permission={['create_10', 'create_17']} component={AddClosing} />}/>
              <Route path="daily-closing/add-closing/upload" element={<Protected permission={['create_10', 'create_17']} component={UploadClosing} />}/>
              <Route path="daily-closing/add-closing/finish" element={<Protected permission={['create_10', 'create_17']} component={FinishClosing} />}/>

              <Route path="refund-corporate" element={<Protected permission={['create_10', 'create_17', 'read_10', 'read_17']} component={RefundCorporate} />}/>
              <Route path="refund-payment" element={<Protected permission={['create_10', 'create_17', 'read_10', 'read_17']} component={RefundPayment} />}/>


              <Route path='retake-exam' element={<Protected permission={['read_9']} component={RetakeExam} />} />
              <Route path='retake-exam/book-retake' element={<Protected permission={['create_9']} component={BookRetake} />} />
              <Route path='retake-exam/book-retake/payment' element={<Protected permission={['create_9']} component={BookRetakePayment} />} />
              <Route path='booking-management/book-details/:id' element={<Protected permission={['read_9']} component={BookingManagementRetake} />} />

              <Route path='ledger-setup' element={<Protected permission={['read_10']} component={BranchList} />} />
              <Route path='ledger-setup/:id' element={<Protected permission={['read_10']} component={BranchLedger} />} />

              <Route path='advanced-payment' element={<Protected permission={['read_10']} component={AdvanceList} />} />
              <Route path='advanced-payment/new' element={<Protected permission={['create_10']} component={AdvanceNew} />} />

              <Route path='scores' element={<Protected permission={['read_19']} component={Score} />} />
              <Route path='amana-invoice' element={<Protected permission={['read_10', 'read_18']} component={AmanaInvoice} />} />
              <Route path='amana-invoice/:id' element={<Protected permission={['read_10', 'read_18']} component={AmanaBatch} />} />


              <Route path='product' element={<Protected permission={['read_16']} component={ProductView} />} />
              <Route path='product-add' element={<Protected permission={['read_16', 'create_16']} component={AddProduct} />} />

              <Route path='instructor-scheduling' element={<Protected permission={['read_16', 'create_16']} component={InstructorScheduling} />} />
              <Route path='instructor-courses' element={<Protected permission={['read_16', 'create_16']} component={InstructorCourses} />} />
              <Route path='instructor-courses/:id' element={<Protected permission={['read_16', 'create_16']} component={InstructorBooking} />} />

              <Route path='examiner-score' element={<Protected permission={['read_16', 'create_16']} component={ExaminerScore} />} />
              <Route path='examiner-scheduling' element={<Protected permission={['read_16', 'create_16']} component={ExaminerScheuling} />} />


              <Route path='reports' element={<Protected permission={['read_16', 'create_16']} component={Reports} />} />
              <Route path='reports/branch-revenue' element={<Protected permission={['read_16', 'create_16']} component={BranchRevenueReports} />} />
              <Route path='reports/detailed-revenue' element={<Protected permission={['read_16', 'create_16']} component={DetailedRevenueReports} />} />
              <Route path='reports/curent-course-revenue' element={<Protected permission={['read_16', 'create_16']} component={CurentCourseRevenueReports} />} />
              <Route path='reports/current-traning-revenue' element={<Protected permission={['read_16', 'create_16']} component={CurentTrainingRevenueReports} />} />
              <Route path='reports/statement-of-cancellation-course' element={<Protected permission={['read_16', 'create_16']} component={StatmentCoursegRevenueReports} />} />
              <Route path='reports/statement-of-cancellation-traning' element={<Protected permission={['read_16', 'create_16']} component={StatmentTraningRevenueReports} />} />


              <Route path='workflow' element={<Protected permission={['read_16', 'create_16']} component={WorkFlow} type={'super admin'} />} />
              <Route path='workflow/configration/:id' element={<Protected permission={['read_16', 'create_16']} component={WorkFlowConfigration} type={'super admin'} />} />



            </Route> */}
            {/* <Route path="/corporate" element={<Protected type={'corporate'} component={CorporateAdmin} />} >
              <Route path="dashboard" element={<Protected type={'corporate'} component={CorporateDashboard} />}/>
              <Route path="courses" element={<Protected type={'corporate'} component={CorporateBooking} />}/>
              <Route path="course/:id" element={<Protected type={'corporate'} component={CorporateCourse} />}/>
              <Route path="courses/confirm/:id" element={<Protected type={'corporate'} component={CourseDetails} />}/>
              <Route path="scheduling" element={<Protected type={'corporate'} component={CorporateScheduling} />}/>
            </Route> */}

            <Route path="products" element={<ProductsList />} />
            <Route path="product-details/:id" element={<ProductsDetails />} />
            <Route path="product-payment/:id" element={<ProductPayment />} />

            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
